<template>
  <div class="profile_bg overflow-hidden">
    <b-container fluid>
      <b-row>
        <b-col sm="12" class="overflow-hidden">
          <div
            class="d-flex align-items-center justify-content-between"
            :class="$i18n.locale === 'ar' ? 'dir-rtl' : 'dir-ltr '"
          >
            <h4 style="margin-top: 80px">
              <div style="font-size: 20px">
                {{ $t('profile.header') }}
              </div>
            </h4>
          </div>

          <div class="content">
            <!-- phone number -->
            <div
              class="content-data"
              :class="$i18n.locale === 'ar' ? 'dir-rtl' : 'dir-ltr '"
            >
              <p>{{ $t('profile.inputs.phone') }}</p>
              <input :value="userData.phoneNumber" disabled />
            </div>

            <!-- subscription start date -->
            <div
              class="content-data"
              :class="$i18n.locale === 'ar' ? 'dir-rtl' : 'dir-ltr '"
            >
              <p>{{ $t('profile.inputs.subStart') }}</p>
              <input :value="userData.sub_starts_at_converted" disabled />
            </div>

            <!-- subscription end date -->
            <div
              class="content-data"
              :class="$i18n.locale === 'ar' ? 'dir-rtl' : 'dir-ltr '"
            >
              <p>{{ $t('profile.inputs.subEnd') }}</p>
              <input :value="userData.sub_expires_at_converted" disabled />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div class="footer">
      <p>
        {{ $t('profile.footer') }}
      </p>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  name: 'Profile',
  data () {
    return {
      baseUrl: 'https://high-fit.net:3459/api/v1',
      userData: {}
    }
  },
  methods: {
    async fetchProfile () {
      // fetch user profile data
      try {
        const res = await axios.get(this.baseUrl + '/auth/etisalat/profile', {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('highFitToken')
          }
        })
        if (res && res.status === 200) {
          this.userData = res.data.data
        }
      } catch (err) {
        console.error(err)
      }
    }
  },

  computed: {
    getCountryCode () {
      return localStorage.getItem('countryCode')
    }
  },

  async mounted () {
    if (this.getCountryCode !== '971') {
      this.$router.push('/')
    } else {
      await this.fetchProfile()
    }
  }
}
</script>

<style lang="scss">
.dir-ltr {
  direction: ltr;
  text-align: left;
}

.dir-rtl {
  direction: rtl;
  text-align: right;
}
.hover-buttons .btn {
  background-color: #defe71;
  color: black;
  border-color: #defe71;
}

.profile_bg {
  font-family: 'FontAwesome';
  background-image: url('../../assets/images/subscribe/subscribe_desk_bg.png');
  min-height: 100vh;
  background-position: center;
  background-size: 100% 100%;

  @media (max-width: 480px) {
    background-image: url('../../assets/images/subscribe/bg_mobile.png');
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin: 40px 0;
  gap: 10px;
}

.content-data {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.content-date p {
  color: #fff;
}

.content-data input {
  width: 170px;
  padding: 0;
  height: 30px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: transparent;
  text-align: center;
}

.footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 12px;
  color: #a6a5a5;
  text-align: center;
}
</style>
